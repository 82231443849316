import React, { useState } from "react"
import styles from "./betaSignUp.module.css"
import emailClient from "emailjs-com"
import { Link } from "gatsby"

function validateEmail(email) {
  const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailReg.test(String(email).toLowerCase())
}

const BetaSignUp = () => {
  const [submitStatus, setSubmitStatus] = useState("")
  const [org, setOrgName] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const onOrgChange = input => {
    setOrgName(input.target.value)
    setSubmitStatus("")
  }
  const onNameChange = input => {
    setName(input.target.value)
    setSubmitStatus("")
  }
  const onEmailChange = input => {
    setEmail(input.target.value)
    setSubmitStatus("")
  }

  const betaSignUpHandler = () => {
    emailClient.init(process.env.EMAILJS_CLIENT_KEY)
    const serviceID = process.env.EMAILJS_SERVICE_ID
    const templateID = process.env.EMAILJS_BETA_TEMPLATE_ID
    const templateParams = { org, name, email }
    if (!validateEmail(email)) {
      console.log(templateParams)
      return setSubmitStatus("fail")
    }
    emailClient.send(serviceID, templateID, templateParams).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text)
        setSubmitStatus("success")
      },
      function (error) {
        console.log("FAILED...", error)
        setSubmitStatus("fail")
      }
    )
  }
  return (
    <>
      <div className={styles.container} id="beta">
        <div className={styles.loginCard}>
          <div className={styles.login}>
            <div className={styles.header}>
              <span className={styles.highlight}>Sign up</span> to get notified
              when we go <span className={styles.highlight}>live!</span>
            </div>
            <div>
              <input
                onChange={onOrgChange}
                placeholder="Organization"
                type="text"
                className={styles.emails}
              />
            </div>
            <div>
              <input
                onChange={onNameChange}
                placeholder="Name"
                type="text"
                className={styles.passwords}
              />
            </div>
            <div>
              <input
                onChange={onEmailChange}
                placeholder="Email"
                type="email"
                className={styles.passwords}
              />
            </div>
            <div className={styles.box}>
              <div
                className={styles.button}
                onClick={betaSignUpHandler}
                role="button"
              >
                <div className={styles.button_text}>Sign Up</div>
              </div>
            </div>
            {submitStatus === "success" ? (
              <div className={styles.success}>
                <span className={styles.bold}>Success!</span> You can now return
                to home.
              </div>
            ) : submitStatus === "fail" ? (
              <div className={styles.fail}>
                <span className={styles.bold}>Error!</span> Something went
                wrong, please try again.
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.fineprint}>
        By signing up, you agree to Finsimpl’s{" "}
        <Link to="/legal/">Terms of Use and Privacy Policy</Link>
      </div>
    </>
  )
}

export default BetaSignUp
