import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import BetaSignUp from "../components/betaSignUp"

const BetaSignUpPage = () => (
  <Layout type={1}>
    <Menu type={2} btype={3} />
    <BetaSignUp />
    <Footer type={1} />
  </Layout>
)

export default BetaSignUpPage
